import { ComponentProps } from 'react';
import { useIntl } from 'react-intl';
import { Button, IconButton } from 'ui';
import { cn } from 'utils/cn';

// Use this file for Button instances that are used in multiple places

type ButtonProps = ComponentProps<typeof Button>;
type IconButtonProps = ComponentProps<typeof IconButton>;

const LinkButton = ({
  className,
  icon = { name: 'angle-right', styling: 'fal' },
  iconPosition = 'after',
  variant = 'naked',
  ...props
}: ButtonProps) => (
  <Button
    iconPosition={iconPosition}
    icon={icon}
    variant={variant}
    className={cn('w-fit text-primary-30 hover:underline', className)}
    {...props}
  />
);

const ReadMoreButton = ({ readMore = false, ...props }: Omit<ButtonProps, 'children'> & { readMore?: boolean }) => {
  const { formatMessage } = useIntl();
  const showMoreLabel = formatMessage({ id: 'general_read_more_btn_text' });
  const showLessLabel = formatMessage({ id: 'general_read_less_btn_text' });

  return <LinkButton {...props}>{readMore ? showLessLabel : showMoreLabel}</LinkButton>;
};

const ShowMoreButton = ({ showMore = false, ...props }: Omit<ButtonProps, 'children'> & { showMore?: boolean }) => {
  const { formatMessage } = useIntl();
  const showMoreLabel = formatMessage({ id: 'general_show_more' });
  const showLessLabel = formatMessage({ id: 'general_show_less' });

  return <LinkButton {...props}>{showMore ? showLessLabel : showMoreLabel}</LinkButton>;
};

const WishlistIconButton = ({
  active,
  className,
  ...props
}: Omit<IconButtonProps, 'children' | 'icon'> & { active?: boolean }) => (
  <IconButton
    className={cn('size-10', active ? 'text-error' : '', className)}
    icon={{ name: 'heart', styling: active ? 'fas' : 'fal' }}
    {...props}
  />
);

export { LinkButton, ReadMoreButton, ShowMoreButton, WishlistIconButton };
